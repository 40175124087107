import { App } from "@/constants";
import {
    array,
    boolean,
    enums,
    nullable,
    number,
    object,
    string,
} from "superstruct";

const InitOptionsSchema = object({
    enabledApps: array(enums(Object.values(App))),
    customerId: nullable(number()),
    customerEmail: nullable(string()),
    customerFirstName: nullable(string()),
    customerLastName: nullable(string()),
    isB2bCustomer: boolean(),
    customerTags: array(string()),
    moneyFormat: string(),
    moneyWithCurrencyFormat: string(),
    shopCurrency: string(),
    currencySymbol: string(),
    storefrontApiAccessToken: nullable(string()),
    countryIsoCode: string(),
    languageIsoCode: string(),
    currencyIsoCode: string(),
    gwI18nLocale: string(),
    shopName: string(),
    isDiscountApplyAvailable: boolean(),
    isCartAvailable: boolean(),
});

export default InitOptionsSchema;
